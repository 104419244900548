import truncateInMiddle from './truncateInMiddle';
function truncateResponse (response) {
    let filename = "";
    
    if (response) {
        const colonIndex = response.indexOf(':');
        if (colonIndex !== -1 && colonIndex < response.length - 1) {
           filename = response.substring(colonIndex + 1).trim();
           filename = "Duplicate file exists with the filename:" + truncateInMiddle(filename, 16);
        }
  
    }
    return filename;
  }
  export default truncateResponse;