import { colors } from "../../utils/theme";
import styled from "styled-components";


export const customStyles = (position) => {
  return {
    content: {
      position: 'absolute',
      top: `calc(${position.top}px + 0.4vw)`, 
      left: `calc(${position.left}px - 17vw)`, 
      backgroundColor: colors.newblueTheme,
      borderRadius: '20px',
      width: '20%',
      height: '50%',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.6)',
    },

    '@media (max-width: 768px)': {
     
      content: {
        top: `calc(${position.top}px + 1vw)`, 
        left: `calc(${position.left}px + 1vw)`, 
        width: '40%',
        height: '55%',
      },
    },
    '@media (min-width: 768px)': {
     
      content: {
        top: `calc(${position.top}px + 1vw)`, 
        left: `calc(${position.left}px -18vw)`,
        width: '20%',
        height: '50%', 
      },
    },
    
  };
  
};


  export const CrossWrapper = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ModalHeadling2 = styled.div`
  text-align: left;
  margin-bottom: 2rem;
  color: white;
`;

export const ReleaseWrapper = styled.div`
  display: flex;
  justifyContent: space-between;
  color:white;
`;
export const Divider = styled.hr`
  border: 0.5px solid white ;
  margin-top: 5px;
  width:100%;
  padding: 0;

`;

export const Date = styled.div`
  fontSize: 14px;
  color: white;
`;

export const ReleaseNote = styled.div`
  font-size: 14px;
  color: white;
  margin-top: 10px;
`;

export const Header = styled.span`
  font-size:22px;

`;

export const ButtonWrapper = styled.div`
display:flex;
justify-content: center;
align-items: center;
margin-top: 20px;
`;




export const UL = styled.ul`
list-style: none;
padding: 0;
margin-top: 18px;
`;

export const LI = styled.li`
margin: 0 0 10px 25px;
position: relative;
font-size: 15px;

&::before {
  content: '✔' ; 
  position: absolute;
  left: -25px;
  color: yellow; 
 
  font-weigth: 700;
}
`;



export const GotItButton = styled.button`
  color: white;
  border:1px solid white;
  padding: 2px 2px 2px 2px;
  width: 80px;
  height: 40px;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    border:1px solid gray;

  }
`;

export const Notes = styled.p`
font-size: 15px;
 text-align: center;
`;
