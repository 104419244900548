// @ts-check

import axapi from "./axios";

/**
 * @typedef {Object} Paper
 * @property {string} _id
 * @property {string} originalName
 * @property {string} path
 * @property {string} user
 * @property {boolean} processed
 * @property {boolean} pinecone
 * @property {boolean} deleted
 * @property {string} fileHash
 * @property {string} lastOpenTime
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} __v
 */

/**
 * @returns {Promise<Paper[]>}
 */
export const getPaperList = async () => {
  return await axapi.get("/benchmark/paperList").then(res => res.data.paperList);
};

/**
 * @typedef {Object} BenchmarkQuestion
 * @property {string} query
 * @property {string} shortAnswer
 * @property {string} longAnswer
 *
 * @typedef {Object} BenchmarkData
 * @property {string} name
 * @property {string} paperId
 * @property {BenchmarkQuestion[]} questions
 */

/**
 * @param {string} [paperId]
 * @returns {Promise<BenchmarkData[]>}
 */
export const getBenchmarkData = async paperId => {
  const response = await axapi.get("/benchmark/questionList", { params: { paperId } });
  return response.data.questions;
};
