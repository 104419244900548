import { io } from 'socket.io-client';
import { baseURL } from './utils/axios';
import useLogout from "./components/misc/logout";


let socket = null;

const initSocket = () => {
  const token = localStorage.getItem('token');
//console.log('socket token', token)
  if (!socket && token) {
    socket = io(baseURL, {
      withCredentials: true,
      query: { token },
      transports: ['websocket'], // Use only WebSockets
    });

    socket.on('connect', () => {
      console.log('Socket connected');
    });

    socket.on('LOGOUT', () => {
      console.log('Logout event received');
      disconnectSocket();
      
    });
  } else if (!token) {
    console.log('No token found. Socket not connected.');
  }

  return socket;
};

// Function to disconnect the socket
const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null; // Reset the socket instance
  }
};

export { initSocket, disconnectSocket };
