import "react-chat-elements/dist/main.css";
import remarkGfm from "remark-gfm";
import { Box, ButtonGroup, IconButton, Button, ToggleButton } from "@mui/material";

import { theme } from "./../../utils/mui-theme";
import { Send } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { selectedFile as selectedFileAtom } from "./../../state/file";
import ReactMarkdown from "react-markdown";
import nlp from "compromise";
import { toast } from "react-toastify";
import useLogout from "./../misc/logout";
import { sessionExpiredState } from "./../../state/session";
import { selectedSourceState } from "../../state/highlight";
import Tooltip from "./Tooltip";
import { IoMdCopy, IoIosCopy } from "react-icons/io";
import { BsGraphUpArrow } from "react-icons/bs";
import axapi from "../../utils/axios";
import CircularProgress from "@mui/material/CircularProgress";
import { autoClose } from "../../constants";
import { ReactComponent as ToastInfoIconSvg } from "./../../icons/toast-info.svg";
import { easyModePopup } from "../../state/easyModePopup";
import isBenchmark from "../../utils/is-benchmark";
import { getBenchmarkData } from "../../utils/benchmark";

/**
 * Custom React hook to automatically scroll a chat window to the bottom whenever new messages are added.
 * @param {any} dep - Dependency that triggers the effect when changed.
 * @returns {object} - React ref object for the chat window.
 */
const useChatScroll = dep => {
  const ref = useRef(); // Create a reference to the chat window element

  // useEffect is used to perform side effects in function components
  useEffect(() => {
    if (ref.current) {
      // Check if the reference to the chat window element exists
      ref.current.scrollTop = ref.current.scrollHeight; // If it does, scroll the chat window to the bottom
    }
  }, [dep]); // The effect is triggered whenever the dependency 'dep' changes
  return ref; // Return the ref object, which can be used to access and manipulate the chat window element
};

/**
 * Processes internal text, converting sources to links and making digging deeper content clickable.
 * @param {string} internalText - The internal text to be processed.
 * @returns {string} - The processed text with sources converted to links and clickable digging deeper content.
 */
const processInternalText = (internalText, previousMessage, isLong = false, questions) => {
  const ifLongReply = previousMessage && isLong;

  // const ifLongReply =  internalText && internalText.endsWith(' #long');
  const infoNotFound = internalText.match(/better[^a-z\n\r]+questions/i) ? true : false;

  // Split the internal text into answerText and diggingDeeper parts
  // let [answerText, diggingDeeper] = internalText.split(
  //   /[\n\r][^a-z\n\r]*digging[^a-z\n\r]+deeper[^a-z\n\r]*[\n\r]/i
  // );
  const diggingDeeperKey = infoNotFound ? ["better", "question"] : ["dig", "deep"];
  let [answerText, diggingDeeper] = internalText.split(
    new RegExp(
      `[\\n\\r][^a-z\\n\\r]*.{0,60}${diggingDeeperKey[0]}.{0,5}[^a-z\\n\\r]*.{0,5}${diggingDeeperKey[1]}.{0,60}[^a-z\\n\\r]*[\\n\\r]\\s*(?=[-\\d])`,
      "i"
    )
  );

  // Replace underscores with bullet points

  answerText =
    answerText?.replace(/\[([\d\s,]+)\]/g, (_, matched) => {
      return matched
        .replace(/\s+/g, "")
        .split(",")
        .map(num => `[${num}]`)
        .join("");
    }) || "";
  answerText = answerText?.replace(/\s*\[(\d+)]\s*/g, "[$1](#)") || "";
  //answerText = answerText?.replace(/\s*\[(\d+)]\s*/g, '' ) || '' ;
  answerText = answerText?.replace(/(?<=[\r\n])[^\r\n]*(summary|conclusion)[^\r\n]*(?=[\r\n])/, "");

  if (ifLongReply && !infoNotFound) answerText = paragraphToBullet(answerText);

  const resetIndex = answerText.indexOf("[RESET]");
  if (resetIndex !== -1) {
    // Keep the text after the [RESET] token
    answerText = answerText.substring(resetIndex + "[RESET]".length).trim();
  }

  //console.log('answerText after ', answerText)
  // Delete sources from inside "Digging Deeper" questions and make them clickable
  diggingDeeper = diggingDeeper?.replace(/\[[^\]+]\]/g, "");
  diggingDeeper = diggingDeeper?.replace(/^\s*([-*\d]\.?\s*)([^\n\r]*)/gm, "$1[$2](#)");
  diggingDeeper = diggingDeeper?.replace(/^[^-*\d][^\n\r]*/gm, "");

  if (isBenchmark() && diggingDeeper) {
    diggingDeeper = questions.reduce((acc, { query }) => {
      return `${acc}- [${query}](#)\n`;
    }, "");
  }

  // If there's no diggingDeeper content, return the answer internal text

  // if (!diggingDeeper) return answerText;

  // // Convert the diggingDeeper content to clickable links
  // const clickDiggingDeeper = diggingDeeper.replace(
  //   /^(\s*[-*\d]\.?\s*)([^\n\r]*)/gm,
  //   '$1[$2](#)'
  // );
  if (diggingDeeper) {
    const clickLong =
      ifLongReply || infoNotFound
        ? ""
        : `\n\n[Need a more detailed answer?](${encodeURIComponent(`${previousMessage} #long`)})`;
    if (isBenchmark()) return `${answerText}${clickLong}\n\n**Questions:**\n${diggingDeeper}`;
    return `${answerText}${clickLong}\n\n**Digging deeper:**\n${diggingDeeper}`;
  } else {
    return answerText;
  }
  // Combine the answerText and the modified diggingDeeper content
  // return `${answerText}\n\n**Digging deeper:**\n${clickDiggingDeeper}`;
};

/**
 * Converts text structured in paragraph form into bullet form.
 *
 * @param {string} text - The input text containing paragraphs.
 * @returns {string} - The text reformatted to use bullet form.
 */
function paragraphToBullet(text) {
  let paragraphs = text.split(/[\r\n]+/).filter(p => p.trim() !== "");

  paragraphs = paragraphs.map((paragraph, i) => {
    const doc = nlp(paragraph);
    const sentences = doc.sentences().out("array");
    return sentences.join("\n- ").trim();
  });

  return paragraphs.join("\n\n");
}

/**
 * Chat component that displays messages and handles user interactions.
 * @returns {JSX.Element} - React component.
 */
export const Chat = ({ socket }) => {
  const [query, setQuery] = useState("");
  const [selectedFile] = useRecoilState(selectedFileAtom);
  const [messages, setMessages] = useState([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [mode, setMode] = useState("scholar");
  const [copiedText, setCopiedText] = useState("");
  const [, setSessionExpired] = useRecoilState(sessionExpiredState);
  const logout = useLogout();
  const [copied, setCopied] = useState(Array(messages.length).fill(false));
  const [, setSelectedSource] = useRecoilState(selectedSourceState);
  const [isChatLoading, setChatLoading] = useState(false);
  const [isBenchmarkLoading, setBenchmarkLoading] = useState(false);
  const [benchmarkData, setBenchmarkData] = useState();
  const activeFileIdRef = useRef(null);
  const [isEasyModeToastShowing, setIsEasyModeToastShowing] = useState(false);
  const [easyMode, setEasyMode] = useRecoilState(easyModePopup);
  /**
   * Handle mode change for the chat.
   * @param {string} newMode - New chat mode.
   */
  const handleModeChange = newMode => {
    if (newMode === "easy" && !isEasyModeToastShowing) {
      // Set the state to indicate that a toast is being displayed
      setIsEasyModeToastShowing(true);
      setEasyMode(true);
      toast.info(
        "Coming Soon! Clear Science and zero Jargon. Stay tuned for the insights that make the science seem like child's play.",
        {
          autoClose,
          onClose: () => {
            // Reset the state when the toast is closed
            setIsEasyModeToastShowing(false);
            setEasyMode(false);
          },
          progressStyle: {
            background: "#000066",
          },
          icon: () => <ToastInfoIconSvg />,
        }
      );
    }
    setMode("scholar");
  };

  /**
   * Handle copy action for a message.
   * @param {string} originalText - Text to be copied.
   * @param {number} index - Index of the message, required to copy the specific message
   */
  const handleCopy = async (originalText, index) => {
    const cleanedText = originalText
      .replace(/\[RESET\]/g, "")
      .replace(/\[[0-9]+\]/g, "")
      .replace(/\*\*Digging deeper:[\s\S]*/i, "")
      .replace(/Digging deeper:[\s\S]*/i, "") // for long answer response
      .trim();

    setCopiedText(cleanedText);

    // Copy the text to the clipboard directly
    navigator.clipboard.writeText(cleanedText).then(
      () => {
        // Text successfully copied
        const updatedCopied = Array.from(copied);
        updatedCopied[index] = true;
        setCopied(updatedCopied);

        setTimeout(() => {
          const resetCopied = Array.from(updatedCopied);
          resetCopied[index] = false;
          setCopied(resetCopied);
        }, 1000);
      },
      err => {
        // Unable to copy the text
        console.error("Unable to copy text to clipboard", err);
      }
    );
  };

  /**
   * Handle form submission for sending a user message.
   * @param {Object} e - Form submission event.
   */
  const handleFormSubmit = async e => {
    e.preventDefault();
    if (!query) return;
    socket.emit("message", {
      query,
      fileId: selectedFile._id,
      mode: "",
      type: "user-typed",
    });
    addMessage(query, "", "query");
    setQuery("");
  };

  /**
   * Adds a new message to the chat
   * @param {String} message - The text of the message
   * @param {String} type - 'query' or 'response'
   */
  const addMessage = (message, sources, type, isError = false) => {
    setMessages(prevHistory => [
      ...prevHistory,
      {
        position: type === "query" ? "right" : "left",
        type: "text",
        text: message.endsWith("#long") ? message.split(" #long")[0] : message,
        sources: sources,
        reset: false,
        isError: isError, // Add isError property to the message object
        isLong: message.endsWith("#long"),
      },
    ]);
  };

  /**
   * Appends text to a particular message
   * @param {String} append - The text to append
   * @param {number} index - The index of the element to retrieve. If negative, counts from the end of the array.
   */
  const appendMessage = (append, sources, index) => {
    setMessages(prevHistory => {
      if (index < 0) {
        index = prevHistory.length + index;
      }

      // Ensure the index is within bounds
      if (index >= 0 && index < prevHistory.length) {
        return prevHistory.map((message, i) =>
          i === index ? { ...message, text: message.text + append, sources: sources, reset: false } : message
        );
      } else {
        return prevHistory;
      }
    });
  };

  // Set up socket event listeners

  useEffect(() => {
    const handleStart = () => {
      setIsStreaming(true);
    };

    const handleEnd = () => {
      setIsStreaming(false);
    };

    const handleNew = payload => {
      addMessage(payload.word, payload.sources, "response");
    };

    const handleAppend = payload => {
      appendMessage(payload.word, payload.sources, -1);
    };

    const handleReset = () => {
      resetMessage();
    };
    const handleLogout = () => {
      setSessionExpired(true);
      logout();
    };
    const handleError = () => {
      addMessage("Oops! Something went wrong!", "", "response", true);
      setIsStreaming(false);
    };
    // Set up socket event listeners
    socket.on("START", handleStart);
    socket.on("END", handleEnd);
    socket.on("NEW", handleNew);
    socket.on("APPEND", handleAppend);
    socket.on("RESET", handleReset);
    socket.on("LOGOUT", handleLogout);
    socket.on("ERROR", handleError);

    // Cleanup previous conversations when a new PDF is selected
    return () => {
      setMessages([]);
      socket.off("START", handleStart);
      socket.off("END", handleEnd);
      socket.off("NEW", handleNew);
      socket.off("APPEND", handleAppend);
      socket.off("RESET", handleReset);
      socket.off("LOGOUT", handleLogout);
      socket.off("ERROR", handleError);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, selectedFile]);

  // Function to reset the message at a specific index
  const resetMessage = () => {
    setMessages(prevMessages => {
      // Check if there are messages to reset
      if (prevMessages.length === 0) return prevMessages;

      return prevMessages.map((message, i) => {
        if (i === prevMessages.length - 1) {
          return { ...message, text: "", reset: true };
        }
        return message;
      });
    });
  };

  // Create a ref for automatic scrolling
  const ref = useChatScroll(messages);

  /**
   * Handle digging deeper action triggered by a link click.
   * @param {string} diggingDeeperText - Text for digging deeper.
   */
  // ...

  const handleDiggingDeeper = async diggingDeeperText => {
    if (!diggingDeeperText) return;

    // Process the diggingDeeperText if needed
    const processedDiggingDeeper = processInternalText(diggingDeeperText, null);

    // Add the digging deeper message to the messages state
    addMessage(processedDiggingDeeper, "", "query");

    // Perform any additional actions, e.g., emit a socket event
    socket.emit("message", {
      query: diggingDeeperText,
      fileId: selectedFile._id,
      mode: "",
      type: "auto",
    });
  };
  const handleLongerResponse = async text => {
    if (!text) return;

    // Process the diggingDeeperText if needed
    // const processedText = processInternalText(text + ' #long');
    const processedText = processInternalText(text);

    // Add the digging deeper message to the messages state
    addMessage(processedText, "", "query");

    // Perform any additional actions, e.g., emit a socket event
    socket.emit("message", {
      query: processedText,
      fileId: selectedFile._id,
      mode: "long",
      type: "auto",
    });
  };

  /**
   * Custom renderer for markdown links, converting them into buttons styled as links.
   * @param {Object} props - Properties passed to the renderer component.
   * @returns {ReactElement} - A button styled as a link, with an associated click handler.
   */
  const LinkRenderer = props => {
    let sourceTooltip, rectangle;
    const sourceNum = props?.children?.[0]?.match(/^\[?(\d+)\]?$/);
    const sources = props.sources;

    if (sourceNum && sources && sources.length > 0) {
      const sourceIndex = parseInt(sourceNum[1], 10) - 1; // Arrays are zero-indexed
      const associatedSource = sources[sourceIndex];

      rectangle = associatedSource?.rect;

      let pageNums = rectangle ? rectangle.map(item => item.page) : [];
      // Remove duplicates by converting to a Set and back to an Array
      pageNums = [...new Set(pageNums)];

      const pageNum = pageNums.length > 0 ? "See page number " + pageNums.join(" and ") : "";

      sourceTooltip = pageNum;
    }
    const handleClick = async e => {
      e.preventDefault();

      if (sourceTooltip) {
        setSelectedSource({ areas: rectangle, showHighlights: true });
      } else if (props.href.endsWith(encodeURIComponent("#long"))) {
        // const messageText = props.href.split(encodeURIComponent('#long'))[0];

        const longerResponseText = decodeURIComponent(props.href);
        await handleLongerResponse(longerResponseText);
      } else if (props.href === "#") {
        // Handle digging deeper link click
        const diggingDeeperText = props?.children;
        await handleDiggingDeeper(diggingDeeperText);
      }
    };
    // Return a different component based on whether it's a source link or not
    return sourceNum ? (
      <Tooltip tooltipText={sourceTooltip}>
        <button className="source-link" onClick={handleClick}>
          {props.children}
        </button>
      </Tooltip>
    ) : (
      <button className="link-button" onClick={handleClick}>
        {props.children}
      </button>
    );
  };

  const clearMessages = cb => {
    setMessages(() => {
      cb();
      return [];
    });
  };

  /**
   * Add a summary prompt when the user selects a new file
   */
  const addMessageRef = useRef(addMessage);
  useEffect(() => {
    if (!selectedFile) return;
    activeFileIdRef.current = selectedFile._id;

    // Emit a stop streaming message to the server for the previous PDF
    clearMessages(() => {
      socket.emit("stopStreaming", { fileId: selectedFile._id });
      if (isBenchmark())
        fetchBenchmarkData().then(() => {
          fetchChatHistory();
        });
      else fetchChatHistory();
    });
    setIsStreaming(false);
    setChatLoading(true);
    if (isBenchmark()) setBenchmarkLoading(true);
    const fetchChatHistory = async () => {
      try {
        const response = await axapi.get(`/chat/history/${selectedFile._id}`);
        const chatHistory = response.data;
        // Check if the active file ID has changed since the fetch was initiated
        if (activeFileIdRef.current !== selectedFile._id) {
          return; // If the file has been switched, ignore the result of this fetch
        }
        // Iterate over each chat history item
        chatHistory.forEach(item => {
          // Add the query
          if (item.query) {
            addMessageRef.current(item.query.content, [], "query");
          }
          // Add the response
          if (item.response) {
            addMessageRef.current(item.response.content, item.response.sources, "response");
          }
        });
      } catch (error) {
        console.error("Error fetching chat history:", error);
      } finally {
        // Always append the summary message, regardless of chat history
        if (activeFileIdRef.current === selectedFile._id) {
          if (!isBenchmark())
            addMessageRef.current(
              "Would you like a concise summary of this document?\n\n- [Summarize this document](#)",
              "",
              "response"
            );
        }
        setChatLoading(false);
      }
    };
    const fetchBenchmarkData = async () => {
      try {
        const [{ questions }] = await getBenchmarkData(selectedFile._id);
        setBenchmarkData(questions);
      } finally {
        setBenchmarkLoading(false);
      }
    };
    // Introduce a delay before fetching chat history
    // const delayMillis = 100; // Delay in milliseconds (1000 ms = 1 second)

    // const timeoutId = setTimeout(() => {
    // }, delayMillis);

    // return () => clearTimeout(timeoutId); // Cleanup timeout when component unmounts or selectedFile changes

    //  }
  }, [selectedFile, socket]);

  /**
   * @param {number} index
   * @returns {boolean}
   */
  const canBeBenchmarked = index => {
    const query = messages[index - 1]?.text;
    if (!query) return false;
    const question = benchmarkData?.find(({ query: q }) => q === query);

    return !!question;
  };

  /**
   * @param {string} str
   * @returns {string}
   */
  const removeDiggingDeeper = str => {
    const index = str.indexOf("**Digging deeper**");
    if (index !== -1) {
      return str.substring(0, index);
    }
    return str;
  };

  /**
   * @param {number} index
   * @returns {() => void}
   */
  const handleBenchmark = index => () => {
    const query = messages[index - 1].text,
      response = removeDiggingDeeper(messages[index].text),
      isLong = messages[index - 1].isLong,
      question = benchmarkData.find(({ query: q }) => q === query);

    if (!question) return;

    toast.promise(axapi.post("/benchmark/score", { selectedFile, question, response, isLong }), {
      error: {
        render() {
          return "An error occurred while benchmarking the response.";
        },
      },
      pending: {
        render() {
          return "Benchmarking the response...";
        },
      },
      success: {
        render({ data: res }) {
          console.info(res.data.answer);
          return `Score: ${res.data.answer.score}. Check console for more details.`;
        },
      },
    });
  };

  /**
   * Build the element
   */
  return (
    <div className={`px-5 lg:px-0 p4 rounded-md bg-white ${selectedFile ? "w-[50%]" : "w-[85%]"}`}>
      {easyMode && <div className="backdrop ml-[17%] z-50" />}
      <Box
        className="bg-[#F2F2F8]"
        sx={{
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <ButtonGroup
          variant="outlined"
          sx={{
            height: "90%",
            // border: "1px solid white",
            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            borderRadius: "999px",
            backgroundColor: "white",
          }}>
          <ToggleButton
            value="scholar"
            selected={mode === "scholar"}
            onChange={() => handleModeChange("scholar")}
            style={{
              backgroundColor: mode === "scholar" ? "#000066" : "white",
              border: "2px solid white",
              borderRadius: "999px",
              height: "98%",
              fontWeight: mode === "scholar" ? 800 : "normal",
              textTransform: "capitalize",
              fontSize: "14px",
              color: mode === "scholar" ? "white" : "#0037A5",
              padding: "14px",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}>
            Scholar Mode
          </ToggleButton>
          <ToggleButton
            value="easy"
            selected={mode === "easy"}
            onChange={() => handleModeChange("easy")}
            style={{
              height: "98%",
              border: "none",
              borderRadius: "999px",
              textTransform: "capitalize",
              fontSize: "16px",
              backgroundColor: mode === "easy" ? "#0037A5" : "white",
              color: mode === "easy" ? "white" : "#000066",
            }}>
            Easy Mode
          </ToggleButton>
        </ButtonGroup>
      </Box>
      <div className="border border-gray-300 h-[calc(100vh-90px)] rounded-lg overflow-auto bg-bkg w-full" ref={ref}>
        {isBenchmarkLoading && isChatLoading ? (
          <div className="flex justify-center items-center h-[93vh]">
            <CircularProgress />
          </div>
        ) : (
          <div className="overflow-auto flex flex-col">
            {messages.map((message, index) => (
              <div
                className={`flex flex-col ${message.position === "left" ? "items-start" : "items-end"} mt-2 mb-2 px-1`}
                key={index}>
                <div
                  className={`${
                    message.position === "left" ? "bg-[#F1F6FF] mr-4" : "bg-[#F3F3F3] ml-4"
                  } text-left rounded-lg p-4 chat-box  max-w-max  group relative ${
                    message.isError ? "text-red-500" : message.position === "left" ? "text-textPrimary" : ""
                  }`}>
                  <ReactMarkdown
                    components={{
                      a: props => <LinkRenderer {...props} sources={message.sources} />,
                    }}
                    remarkPlugins={[remarkGfm]}
                    children={processInternalText(
                      message.text,
                      index > 0 ? messages[index - 1].text : "Summarize this document",
                      index > 0 ? messages[index - 1].isLong : false,
                      benchmarkData
                    )}
                  />

                  {isBenchmark() && canBeBenchmarked(index) && !isStreaming && (
                    <div className="text-left  bg-transparent ml-1">
                      <BsGraphUpArrow
                        size={16}
                        className="hidden group-hover:block cursor-pointer absolute bottom-1 right-10"
                        onClick={handleBenchmark(index)}
                      />
                    </div>
                  )}
                  <div className="text-left  bg-transparent ml-1">
                    {copied[index] ? (
                      <IoIosCopy size={16} className=" text-[#000066] absolute bottom-1 right-1" />
                    ) : (
                      <IoMdCopy
                        size={16}
                        className="hidden group-hover:block cursor-pointer absolute bottom-1 right-1 "
                        onClick={() => handleCopy(message.text, index)}
                      />
                    )}
                  </div>
                  {/* <div className="absolute top-0 right-0 mt-1 mr-1">
                {copied[index] ? (
                  <FaCheck size={16} className="text-green-700" />
                ) : (
                  <RiFileCopyFill
                    size={16}
                    className="text-[#3f3f3f] cursor-pointer"
                    onClick={() => handleCopy(message.text, index)}
                  />
                )}
              </div> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Chat Input Section */}
      <div className="grid  mx-auto  w-full border-t-1 border-gray-300 h-8">
        <form className="flex border border-gray-300 border-t-1 mt-1 rounded-md" onSubmit={handleFormSubmit}>
          <input
            placeholder={
              selectedFile ? (isStreaming ? "Answering..." : "Ask a question...") : "Select a file to start chatting."
            }
            className="w-full p-2 focus:border-[#000066] focus:border"
            name="query"
            disabled={!selectedFile || isStreaming}
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
          <IconButton type="submit">
            <Send className="text-[#000066]" />
          </IconButton>
        </form>
      </div>
    </div>
  );
};
