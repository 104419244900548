import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0037A5',
      chattopbar:'#D4E2FF80'
    },
    secondary: {
      main: '#FDD023',
    },
  },
});
