import React from 'react';
import { Route } from 'react-router-dom';
import AuthGuard from '../utils/guards/auth-guard';
import HomePage from '../components/app/HomePage';

const AuthRoutes = [
  <Route
    key="/"
    path="/"
    exact
    element={<AuthGuard component={<HomePage />} />}
  />,
];

export default AuthRoutes;
