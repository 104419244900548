import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axapi from '../../utils/axios';
import { authState } from '../../state/auth';
import { Snackbar } from '@mui/material';
import { useRecoilState } from 'recoil';
import { initSocket} from '../../socket';
import { jwtDecode } from 'jwt-decode';


export const VerifyEmail = () => {
  const [vcode, setVcode] = useState(0);
  const navigate = useNavigate();
  const [, setAuthData] = useRecoilState(authState);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const socketRef = useRef(null);

  const handleSubmitVerifyEmail = async (e) => {
    e.preventDefault();
    try {
      if (!socketRef.current) {
        socketRef.current = initSocket();
      }
      const res = await axapi.post('/auth/verify-email', {
        vcode,
      });
      const {token} = res.data.body;
      const decoded  = jwtDecode(token);
      setAuthData({
        auth: true,
        token,
        user: decoded.id,
      });
     
     localStorage.setItem('token',token);
      socketRef.current.connect();
      navigate('/');
    } catch (error) {
      const errors = error.response.data.errors;
      setSnackbarText(errors[0]);
      setShowSnackbar(true);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
      }}
    >
      <form onSubmit={handleSubmitVerifyEmail}>
        <Card
          sx={{
            width: 400,
            maxWidth: 600,
          }}
        >
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant="h4">Verify your Email</Typography>
            <div className="my-4">
              <TextField
                label="Verification Code"
                variant="outlined"
                type="number"
                autoFocus={true}
                className="w-full"
                required
                onChange={(e) => setVcode(e.target.value)}
              />
            </div>
            <div className="my-4">
              <Button
                variant="contained"
                className="w-full"
                type="submit"
              >
                Verify
              </Button>
            </div>
          </CardContent>
        </Card>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showSnackbar}
        message={snackbarText}
        onClick={() => {
          setShowSnackbar(false);
        }}
      />
    </div>
  );
};