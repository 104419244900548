import React, { useState } from "react";
import { MdDelete } from "react-icons/md";

import { selectedFilesState,  selectedPdfId as selectedPdfIdAtom } from "../../../state/file";
import { useRecoilState } from "recoil";
import { ReactComponent as DeleteSvg } from './../../../icons/delete.svg';
import { ReactComponent as UploadFileSvg } from './../../../icons/upload_file.svg'

function FileList({
  uploadClick,
  paperList,
  selectedPdfId,
  setSelectedFile,
  handlePdfSelection,
  setShowConfirmationModal,
  setShowDrop2,
  setShowBackdrop,
}) {
  const [, setSelectedPdfId] = useRecoilState(selectedPdfIdAtom);
  const[,setSelectedFiles] = useRecoilState(selectedFilesState);

  return (
    <div>
      <div className="flex mt-6 mb-6 ml-6">
        <button
          className="group text-[#000066] bg-white py-2 px-4 rounded-full shadow-upload flex hover:bg-[#000066] hover:text-white transition"
          onClick={uploadClick}
        >
          <UploadFileSvg className="fill-[#000066] group-hover:fill-white"/> Upload File
        </button>
      </div>
      <div className="h-[60vh]">
        <ul className="ml-2 list-none custom-list h-full overflow-y-auto">
          {paperList.map((file) => (
            <li
              key={file._id}
              className={`group flex justify-between m-2 rounded-[8px] py-2 px-3 paperlist ${file._id === selectedPdfId
                ? "px-1 mb-2 bg-[#0000661A] text-[#3B3B3B] shadow-upload font-semibold"
                : "px-1 text-textSecondary bg-[#D9D9D933]"
                }`}
            >
              <button
                onClick={() => {
                  setSelectedFile(file);
                  setSelectedPdfId(file._id);
                  handlePdfSelection(file.path, file._id);
                  setShowConfirmationModal(false);
                  setShowDrop2(false);
                  setShowBackdrop(false);
                  setSelectedFiles([])
                }}
                title={file.originalName}
                className="text-[14px] tracking-wide overflow-hidden whitespace-nowrap truncate"
              >
               {file.originalName}
              
              </button>
              <button
                onClick={() => {
                  setShowConfirmationModal(file);
                  setShowBackdrop(true);
                }}
                className="cursor-pointer invisible group-hover:visible"
              >
                <DeleteSvg className="hover:fill-[#B52020] transition fill-[#9C9C9C]" />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FileList;
