import React from "react";
import { RxCross1 } from 'react-icons/rx';

function LogoutConfirmationModal({ cancelLogout, handleLogout }) {
  return (
    <div className="absolute  flex items-center justify-center  top-1/2 transform  -translate-y-1/2 left-[250%] z-40 h-[300px] w-[500px] ">
      <div className="bg-white p-8 px-14 rounded-md  shadow-lg relative">
        <div className="absolute top-2 right-2 bg-[#E5E5E5] p-1 rounded-full cursor-pointer" onClick={cancelLogout}>
          <RxCross1 size={12} className="font-semibold"/>
        </div>
        <p className="text-lg mb-4 text-center text-[#0037A5]">
          Are you sure you want to logout
        </p>
        <div className="flex justify-between gap-20">
          <button className="text-[#0037A5]  border border-[#000066] py-[.4rem] px-5 rounded-full text-[14px]" onClick={cancelLogout}>
            No, Cancel
          </button>
          <button className="text-white bg-[#000066] border border-[#0037A5] py-[.4rem] px-5 rounded-full text-[14px]"  onClick={handleLogout}>
            Yes, Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default LogoutConfirmationModal;