import { AppBar, IconButton, Toolbar } from "@mui/material";
import ProgressBar from "./ProgressBar";
import MenuIcon from "@mui/icons-material/Menu";
import { useRecoilState, useRecoilValue } from "recoil";
import { authSelector, authState } from "../../state/auth";
import { useState, useEffect } from "react";
import { FileDrawer } from "../app/FileDrawer";
import { useNavigate } from "react-router-dom";
import { ReleaseNotes } from "../ReleaseNotes/index.jsx";
import { NotificationCircle } from "../NotificationCircle/NotificationCircle.jsx";
import Cookies from "js-cookie";
import { initSocket } from "../../socket";
import { IoIosLogOut } from "react-icons/io";
import { selectedFile as selectedFileAtom } from "./../../state/file";
import { FaBullhorn } from "react-icons/fa";
import { ReactComponent as VeritusLogoSvg } from './../../icons/veritus-logo.svg';
export default function NavBar() {
  const auth = useRecoilValue(authSelector);
  const [, setAuthData] = useRecoilState(authState);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [, setSelectedFile] = useRecoilState(selectedFileAtom);
  const [showNotification, setShowNotification] = useState(
    localStorage.getItem("hasReadReleaseNotesOnApp") !== "true"
  );

  const [modalPostion, setModalPosition] = useState({ top: 0, left: 0 });
  const socket = initSocket();

  const handleLogout = () => {
    setAuthData({
      auth: false,
      token: "",
      user: "",
    });

    //Cookies.remove("user");
    Cookies.remove("token");
    setSelectedFile(null);
    socket.disconnect();
    navigate("/auth/login");
  };

  useEffect(() => {
    const handleResize = () => {
      // Close the modal when the window is resized
      if (isModalOpen) {
        closeModal();
      }
    };

    // Add the event listener
    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isModalOpen]);

  // Function to open the release note modal
  const openModal = (e) => {
    e.preventDefault();
    const annouceIcon = e.target.getBoundingClientRect();

    // Calculate the position for the modal
    const top = annouceIcon.bottom;
    const left = annouceIcon.left;

    // Set the modal position
    setModalPosition({ top, left });

    setModalOpen(true);
    // Hide the notification after opening the modal
    setShowNotification(false);

    // Update the persistent storage to indicate that the user has read the release notes
    localStorage.setItem("hasReadReleaseNotesOnApp", "true");
  };

  // Function to close the release note modal
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <AppBar style={{ position: "inherit" }}>
      <ProgressBar />

      <div className="flex items-center h-12 bg-white justify-between">
        {/* <img className="h-12" src="/logo.png" loading="lazy" alt="logo" /> */}
        <VeritusLogoSvg className="h-10" />
        <div className="flex  items-center ">
        {auth && ( 
       <div className="relative ">
         <FaBullhorn size={24} color="black" className="mr-4 cursor-pointer"
         onClick={openModal}/>
        <span className="absolute h-2 w-2 bg-[red] rounded-full top-0 right-4"></span>
       </div>
            )} 
              <ReleaseNotes isOpen={isModalOpen} onClose={closeModal} position={modalPostion} />
          {auth && (
            //   <IconButton
            //   sx={{ color: 'white', height:'6px', marginLeft:'8px' }}
            //   onClick={handleLogout}

            // >
            //   <img src="/logout.png"  alt="logout" loading="lazy"  style={{height:'25px'}}/>
            //  {/* {showNotification && <NotificationCircle/>} */}
            // </IconButton>
            <IoIosLogOut
              size={25}
              className="text-black mr-4 cursor-pointer"
              onClick={handleLogout}
            />
          )}
        </div>
      </div>

      {/* <div className='h-10 items-start '>
      <Toolbar>
        
        <div className="flex justify-between w-full h-10" >       
        
          <div className="flex items-start h-10">
           
            {auth && (
              <IconButton
                sx={{ color: 'white', height:'8px' }}
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
           
          </div>
          <div className='flex items-start h-10'>
          <div className="flex items-start">
           
     
           {auth && ( 
          <IconButton
                sx={{ color: 'white', height:'8px' }}
                onClick={openModal}
              >
                  <img src='/notificationicon.png' style={{height:'30px'}} alt='noti'  />
                  
              </IconButton>
            )} 
            </div>

           
        
         
          </div>
          
        </div>
      </Toolbar>
      <ReleaseNotes isOpen={isModalOpen} onClose={closeModal} position={modalPostion} />
      <FileDrawer
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen}
      />
      </div> */}
    </AppBar>
  );
}
