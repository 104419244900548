import React from "react";
import { RxCross1 } from "react-icons/rx";
import truncateMiddle from "../../../utils/truncateInMiddle";

function DeleteConfirmationModal({ filename, cancelDelete, confirmDelete }) {

  return (
    <div className="absolute  flex items-center justify-center  top-1/2 transform  -translate-y-1/2 left-[250%] z-[1000] h-[300px] w-[500px] ">
      <div className="bg-white p-8 px-14 rounded-md  shadow-lg relative">
        <div
          className="absolute top-2 right-2 bg-[#E5E5E5] p-1 rounded-full cursor-pointer"
          onClick={cancelDelete}
        >
          <RxCross1 size={12} className="font-semibold" />
        </div>
        <p className="text-lg mb-4 text-center text-[#0037A5]">
          Are you sure you want to delete <br /> "{truncateMiddle(filename, 25)}"
        </p>
        <div className="flex justify-between gap-20">
          <button
            className="text-[#0037A5]  border border-[#000066] py-[.4rem] px-5 rounded-full text-[14px]"
            onClick={cancelDelete}
          >
            No, Cancel
          </button>
          <button
            className="text-white bg-[#000066] hover:bg-[#B52020] hover:border-[#B52020] transition border border-[#0037A5] py-[.4rem] px-5 rounded-full text-[14px]"
            onClick={confirmDelete}
          >
            Yes, Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmationModal;