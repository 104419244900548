import React, { useState } from 'react';
import { Login } from '../../components/auth/login';
import { VerifyEmail } from '../../components/auth/verify-email';

export const LoginPage = () => {
  const [verifyMode, setVerifyMode] = useState(false);

  return (
    <>
      {!verifyMode && <Login setVerifyMode={setVerifyMode} />}
      {verifyMode && <VerifyEmail />}
    </>
  );
};
