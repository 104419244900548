import {
  Button,
  Card,
  CardContent,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { colors } from "../../utils/theme";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import NavBar from "../misc/NavBar";
import axapi from '../../utils/axios';
import { useEffect, useState } from 'react';
import { autoClose } from './../../constants'


const validateToken = async (token) => {
  try {
    const { data } = await axapi.post('/auth/verify-body-token', { token });
    if (!data.auth) {
      throw new Error('Token validation failed');
    }
  } catch (error) {
    throw error;
  }
};

export const CreatePassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const search = useSearchParams();
  const [tokenValid, setTokenValid] = useState(false);
  const [visible, setVisible] = useState(false);
  const [confrimVisible, setConfirmVisible] = useState(false);

  useEffect(() => {
    const token = search[0].get('token');
    if (!token) {
      // Redirect to login if no token is present
      navigate('/auth/login');
      return;
    }

    // Validate the token before component mount
    validateToken(token)
      .then(() => setTokenValid(true))
      .catch(() => {
        setTokenValid(false);
        toast.error('The link has been expired or invalid. Redirecting to login', {
          onClose: () => {
            navigate('/auth/login');
          },
          autoClose,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!tokenValid) {
        // Show Snackbar for invalid token
        throw new Error('Token is not valid');
      }
      // Check if password and confirm password match
      if (password !== confirmPassword) {
      toast.error('Password and Confirm Password do not match', { autoClose });
      return;
    }
      // Call API to create a new password
      await axapi.post('/auth/create-password', {
        confirmPassword,
        password,
        token: search[0].get('token'),
      });
  
      toast.success('Password created successfully. Redirecting to Login', {
        onClose: () => {
          navigate('/auth/login');
        },
        autoClose,
      });
  
     
    } catch (error) {

      toast.error('An error occurred while updating the password. Redirecting to login', {
        onClose: () => {
          navigate('/auth/login');
        },
        autoClose,
      });
    }
  };
  

 
  // Render the component only if the token is valid
  return (
    <>
     <NavBar />
      {tokenValid && (
        <div
          style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "93vh",
          backgroundColor: colors.newblueTheme,
        }}
        >

      <form onSubmit={handleSubmit}>
          <div className="bg-white w-[430px] p-12 pt-5">
            <div className="text-center text-[24px] font-[700] mb-4">
              Create New Password 
            </div>
            <div className="flex flex-col">
            <label
                htmlFor="password"
                className="font-[400] text-[14px] text-[#21272A]"
              >
                Password
              </label>
              <div className=" relative ">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 mt-1 mb-2 border-b  shadow-sm placeholder:text-[16px] focus:border focus:outline-none focus:ring-gray-500 focus:border-gray-300 text-[14px] bg-[#F2F4F8]"
                  placeholder="Password"
                />

                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-3 cursor-pointer "
                    size={20}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-3 cursor-pointer"
                    size={20}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
              <label
                htmlFor="confrimpassword"
                className="font-[400] text-[14px] text-[#21272A]"
              >
               Confirm Password
              </label>
              <div className=" relative ">
                <input
                  type={confrimVisible ? "text" : "password"}
                  name="confirmpassword"
                  autoComplete="password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 mt-1 mb-2 border-b  shadow-sm placeholder:text-[16px] focus:border focus:outline-none focus:ring-gray-500 focus:border-gray-300 text-[14px] bg-[#F2F4F8]"
                  placeholder="Confirm Password"
                />

                {confrimVisible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-3 cursor-pointer "
                    size={20}
                    onClick={() => setConfirmVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-3 cursor-pointer"
                    size={20}
                    onClick={() => setConfirmVisible(true)}
                  />
                )}
              </div>
              <button
                type="submit"
                className="cursor-pointer bg-[#000066] p-2 text-[#FFFFFF] mt-3 text-[16px]"
              >
               Create Password
              </button>
            </div>
          </div>
        </form>
      </div>
     )}
     
    </>
  );
};