import { useState } from "react";

const Tooltip = ({ children, tooltipText }) => {
    const [showTooltip, setShowTooltip] = useState(false);
  
    return (
      <div className="relative inline-flex items-center">
        <div
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {children}
        </div>
        {showTooltip && (
          <div className="absolute max-w-xs whitespace-nowrap bottom-full mb-2 px-3 py-2 bg-gray-700 text-white text-xs rounded-lg shadow-md">
            {tooltipText}
          </div>
        )}
      </div>
    );
  };

  export default Tooltip;