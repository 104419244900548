import React from 'react';
import { Route } from 'react-router-dom';
import AnonymousGuard from '../utils/guards/anonymous-guard';
import { LoginPage } from '../pages/auth/LoginPage';
// import { SignupPage } from '../pages/auth/SignupPage';
import { CreatePassword } from '../components/auth/create-password';

const UnAuthRoutes = [
  <Route
    key="/auth/create-password"
    path="/auth/create-password"
    element={<AnonymousGuard component={<CreatePassword />} />}
  />,
  <Route
    key="/auth/login"
    path="/auth/login"
    element={<AnonymousGuard component={<LoginPage />} />}
  />,
  // <Route
  //   key="/auth/signup"
  //   path="/auth/signup"
  //   element={<AnonymousGuard component={<SignupPage />} />}
  // />,
];

export default UnAuthRoutes;
