import {
  Worker,
  Viewer,
  ProgressBar,
  SpecialZoomLevel,
  ViewMode,
} from "@react-pdf-viewer/core";
import { highlightPlugin, Trigger} from '@react-pdf-viewer/highlight';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { searchPlugin } from "@react-pdf-viewer/search";
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import truncateInMiddle from './../../utils/truncateInMiddle'
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarRightCollapse} from "react-icons/tb";
import { useRecoilValue, useRecoilState } from 'recoil';
import { selectedSourceState } from '../../state/highlight';
import React, { useRef, useEffect, useState} from 'react';
import { ToggleTheme } from "../../state/toggleTheme";
import * as pdfjsLib from "pdfjs-dist";


const getPageDimensions = async (pdfUrl) => {
  const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
  const firstPage = await pdf.getPage(1);
  const viewport = firstPage.getViewport({ scale: 1 });

  return {
    width: viewport.width,
    height: viewport.height
  };
};
const extractSourceAreas = (rectangle, pageDimensions) => {
  return rectangle.map(rect => {
    const pageIndex = rect.page - 1;
    const pageWidth = pageDimensions.width;
    const pageHeight = pageDimensions.height;

    const normalizedHeight = ((rect.topL.y - rect.botR.y) / pageHeight) * 100;
    console.log()
    const normalizedWidth = ((rect.botR.x - rect.topL.x) / pageWidth) * 100;
    const normalizedLeft = (rect.topL.x / pageWidth) * 100;
    const normalizedTop = (rect.topL.y / pageHeight) * 100;

    return {
      pageIndex,
      height: normalizedHeight,
      width: normalizedWidth,
      left: normalizedLeft,
      top: normalizedTop,
    };
  });
};

export const PDFViewer = ({ file, sidebarShow, setSidebarShow }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const searchPluginInstance = searchPlugin();
  const { areas: rectangle, showHighlights } = useRecoilValue(selectedSourceState);
  const [isDarkMode, setIsDarkMode] = useRecoilState(ToggleTheme);
  const [sourceAreas, setSourceAreas] = useState([]);
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const filepath = file.path;
  const filename = file.originalName;
  useEffect(() => {

    const calculateSourceAreas = async () => {
      const pageDimensions = await getPageDimensions(filepath);
      console.log('Page dimensions:', pageDimensions);
      if (pageDimensions) {
        //console.log('reactangle', rectangle);
        const sources = extractSourceAreas(rectangle, pageDimensions);

        setSourceAreas(sources);
        if (sources.length > 0) {
          jumpToPage(sources[0].pageIndex);
        }
      }

    };

    calculateSourceAreas();
  }
    , [ rectangle]);
  // const sourceAreas = [
  //   {
  //     pageIndex: 0,
  //     height: 100,
  //     width: 100,
  //     left: 0,
  //     top: 0,
  //   }];

  const renderHighlights = (props) => (
    <div>

      {showHighlights && sourceAreas
        .filter((area) => area.pageIndex === props.pageIndex)
        .map((area, idx) => (
          <div
            key={idx}
            className="highlight-area"
            style={Object.assign(
              {},
              {
                background: 'yellow',
                opacity: 0.4,
              },
              props.getCssProperties(area, props.rotation)
            )}
          />
        ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });


  return (
    <div style={{ width: "50%", height: "93vh" }} className="bg-white">
      <div
        className="w-full h-[40px] bg-[#000066] px-2 text-white text-center flex items-center justify-between text-[18px] font-[500] hand-pointer"
        title={filename}
      >
        <div className="tooltip">
          {sidebarShow ? (
            <TbLayoutSidebarLeftCollapse
              size={20}
              className="mr-3"
              onClick={() => setSidebarShow(!sidebarShow)} />
          ) : (
            <TbLayoutSidebarRightCollapse
              size={20}
              className="mr-3"
              onClick={() => setSidebarShow(!sidebarShow)} />
          )}
          <span className="tooltiptext">{sidebarShow ? "Hide Sidebar" : "Show Sidebar"}</span>
        </div>{" "}
        {truncateInMiddle(filename, 50)}
        <div></div>
      </div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer
          fileUrl={filepath}
          renderLoader={(percentages) => (
            <div style={{ width: "240px" }}>
              <ProgressBar progress={Math.round(percentages)} />
            </div>
          )}
          defaultScale={SpecialZoomLevel.PageWidth}
          viewMode={ViewMode.SinglePage}
          plugins={[defaultLayoutPluginInstance, searchPluginInstance, highlightPluginInstance, pageNavigationPluginInstance]}
          theme={isDarkMode ? "dark" : "light"}
        ></Viewer>
      </Worker>
    </div>
  );
};
