/**
 * Truncates the middle of a filename if it exceeds a specified maximum length and replaces underscores with blanks.
 * 
 * @param {string} filename - The name of the file to be truncated.
 * @param {number} maxLength - The maximum allowed length of the filename.
 * @returns {string} - The truncated filename with an ellipsis (...) in the middle if it exceeds the maxLength,
 *                     or the original filename if it does not exceed maxLength. All underscores are replaced with blanks.
 */
function truncateMiddle(filename, maxLength) {
    
    // Check if the length of the filename is greater than the maxLength
    if (filename.length <= maxLength) return filename;

    // Calculate the number of characters to keep at the start and end of the filename
    const startCharacters = Math.ceil(maxLength / 2);
    const endCharacters = Math.floor(maxLength / 2) - 3; // 3 for the ellipsis

    // Return the truncated filename with ellipsis in the middle
    return filename.substring(0, startCharacters) + '...' + filename.substring(filename.length - endCharacters);
}

export default truncateMiddle;