import { BrowserRouter } from 'react-router-dom';
import NavBar from './components/misc/NavBar';
import RouterModule from './RouterModule';
import { ThemeProvider } from '@emotion/react';
import { theme } from './utils/mui-theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilState } from 'recoil';
import { ToggleTheme } from './state/toggleTheme';
import { easyModePopup } from './state/easyModePopup';

/**
 * App Component
 *
 * The top-level component that serves as the entry point for the React application.
 * It includes the BrowserRouter for managing the application's navigation,
 * a navigation bar (NavBar), and the main routing logic (RouterModule).
 *
 * @returns {JSX.Element} - The rendered App component.
 */
export default function App() {
  const [isDarkMode, setIsDarkMode] = useRecoilState(ToggleTheme);
  const [easyMode, setEasyMode]=useRecoilState(easyModePopup)
  return (
    <div className={`${isDarkMode ? 'dark' : ''}`}>
     
      <ThemeProvider theme={theme}>  {/* ThemeProvider for providing the application theme */}
       <BrowserRouter> {/* BrowserRouter for managing application navigation */}
         {/* <NavBar /> Navigation bar component */}
         <RouterModule /> {/* Main routing logic */}
        </BrowserRouter> 
        {
          easyMode ? <ToastContainer position="top-center" style={{
            left: '58%',
          }} /> : <ToastContainer position="top-center" />
        }
        
      </ThemeProvider>
    </div>
  );
}
