import { atom } from 'recoil';

export const selectedFile = atom({
  key: 'selectedFile',
  default: null,
  // 'https://publang.s3.ap-northeast-1.amazonaws.com/17a9a6b3-6cd0-4eb0-a4d1-a10d2e657704_file_Contract_Tech_Intern_Ritu_signed.pdf',
});

export const selectedPdfId = atom({
  key: 'selectedPdfId',
  default: null,
})

export const fileToOpen = atom({
  key: 'fileToOpen',
  default: null,
})

export const selectedFilesState = atom({
  key: "selectedFilesState",
  default: [],
});

export const fileToDeleteState = atom({
  key: 'fileToDeleteState',
  default: null,
})