import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AuthRoutes from './routes/auth-routes';
import UnAuthRoutes from './routes/anonymous-routes';
import NotFound from './pages/NotFound';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { authState } from './state/auth';
import axapi from './utils/axios';
import { jwtDecode } from 'jwt-decode';

export default function RouterModule() {
  const [, setAuthData] = useRecoilState(authState);
  const navigate = useNavigate();
  const location = useLocation();
 
  const logout = () => {
    setAuthData({
      auth: false,
      user: '',
      token: '',
    });

    localStorage.removeItem('token');
    navigate('/auth/login');
  };

  const verifyTokenOrRedirectToLogin = async (user) => {
    const { data } = await axapi.post('/auth/verify-token');
    if (data.auth) {
      const decoded = jwtDecode(data.token);
      
      setAuthData({
        auth: true,
        user: decoded.id,
        token: data.token,
      });
     localStorage.setItem(`token`, data.token);
    } else {
      logout();
    }
  };

  useEffect(() => {
   
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      
      setAuthData({
        auth: true,
        user: decoded.id,
        token: '',
      });
      verifyTokenOrRedirectToLogin(decoded.id);
    } else {
      if (location.pathname === '/auth/create-password') {
        setAuthData({
          auth: false,
          user: '',
          token: '',
        });
      
        localStorage.removeItem('token');
        return;
      }
      navigate('/auth/login');
    }
   // socket.on('logout', logout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Routes>
      {AuthRoutes}
      {UnAuthRoutes}
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
}