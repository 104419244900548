import { useRecoilState } from "recoil";
import { authState } from "../../state/auth";
import { selectedFile as selectedFileAtom } from "./../../state/file";
import { disconnectSocket } from "../../socket";
import { useNavigate } from 'react-router-dom';


const useLogout = () => {
    const [, setAuthData] = useRecoilState(authState);
    const [, setSelectedFile] = useRecoilState(selectedFileAtom);
    const navigate = useNavigate();

    const logout = () => {
        setAuthData({
            auth: false,
            token: "",
            user: "",
        });
      
        localStorage.removeItem('token');
        setSelectedFile(null);
        disconnectSocket();
        navigate("/auth/login");
    };

    return logout;
};

export default useLogout;
