/**
 * Define the Release Note component, which will shows pop-up message for the release information when the user clicks the What's New button
 */
import React from 'react';
import Modal from 'react-modal';
import { ReactComponent as ReleaseNotesSvg } from './../../icons/release.svg'
import { GiCancel } from "react-icons/gi";
import {Message} from './message'

import {   
    customStyles,
    CrossWrapper,
    ReleaseWrapper, 
    Divider,
    ReleaseNote,
    Header,   
    ButtonWrapper,
    GotItButton
} from "./styles";


export const ReleaseNotes = ({ isOpen, onClose, position }) => {
    if (!isOpen) {
        return null;
    }

    return (
       
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                contentLabel="Release Notes Modal"
                style={{
                    content: {
                        height: 'min-content',
                        width: '500px',
                        backgroundColor: 'transparent',
                        border:'0px solid transparent',
                        top:'64%',
                    },
                    overlay: {
                        backgroundColor: 'transparent',
                    }
                }}
            >
                <ReleaseNotesSvg onClick={onClose} />
            </Modal>
               
        </>
       

    );
};


