import React, { useState, useEffect } from "react";
import { MdLogout } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { useRecoilState, useRecoilValue } from "recoil";
import { CircularProgress } from "@mui/material";
import axapi from "../../../utils/axios.js";
import { selectedFile as selectedFileAtom, selectedFilesState,} from "../../../state/file";
import { ReleaseNotes } from "../../ReleaseNotes/index.jsx";
import { authState, authSelector } from "../../../state/auth.js";
import { sessionExpiredState } from "../../../state/session.js";
import Drop2 from "../DragAndDrop/FileDragAndDrop.js";
import FileList from "./FileList.js";
import DeleteConfirmationModal from "./DeleteConfirmationModal.js";
import LogoutConfirmationModal from "./LogoutConfirmationModal.js";
import { ToggleTheme } from "../../../state/toggleTheme.js";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import useLogout from "../../misc/logout";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { autoClose } from "../../../constants.js";
import { ReactComponent as VeritusLogoSvg } from './../../../icons/veritus-logo.svg';
import { ReactComponent as VeritusLogoDarkSvg } from './../../../icons/veritus-logo-dark.svg';
import isBenchmark from "../../../utils/is-benchmark.js";
import { getPaperList } from "../../../utils/benchmark.js";

export const useBackdrop=()=>{
  return useState(false);
}

function Sidebar({ userName, sidebarShow }) {
  const auth = useRecoilValue(authSelector);
  const [authData, setAuthData] = useRecoilState(authState);
  const [sessionExpired, setSessionExpired] = useRecoilState(sessionExpiredState);
  const [selectedFile, setSelectedFile] = useRecoilState(selectedFileAtom);
  const [paperList, setPaperList] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [selectedPdfId, setSelectedPdfId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showBackdrop, setShowBackdrop] = useBackdrop();
  const [showDrop2, setShowDrop2] = useState(true);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showLogoutConfirmationBox, setLogoutConfirmationBox] = useState(false);
  const [isDarkMode, setIsDarkMode] = useRecoilState(ToggleTheme);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedFiles, setSelectedFiles] = useRecoilState(selectedFilesState);
  const logout = useLogout();

  const showNotificationInitialState = localStorage.getItem(`hasReadReleaseNotesOnApp:${authData.user}`) === "true" ? false : true;
  const [showNotification, setShowNotification] = useState(showNotificationInitialState);
  useEffect(() => {
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode !== null) {
      setIsDarkMode(savedMode === "dark");
    }
  }, [isDarkMode]);

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const handleResize = () => {
      if (isModalOpen) {
        closeModal();
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isModalOpen]);

  const openModal = (e) => {
    e.preventDefault();
    setShowNotification(false);
    setShowConfirmationModal(false);
    setShowBackdrop(false);

    const top = window.innerHeight - 500;
    const left = 350;

    setModalPosition({ top, left });

    setModalOpen(true);
    setShowNotification(false);

    localStorage.setItem(`hasReadReleaseNotesOnApp:${authData.user}`, "true");
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handlePdfSelection = (pdfPath, pdfId) => {

    setSelectedPdf(pdfPath);
    setSelectedPdfId(pdfId);
  };
  const saveModeToLocalStorage = () => {
    localStorage.setItem("themeMode", isDarkMode ? "light" : "dark");
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        if (isBenchmark()) {
          setIsFetching(false);
          return setPaperList(await getPaperList());
        }
        const res = await axapi.get("/my-files");
        const sortedFiles = res.data.sort(
          (a, b) => new Date(b.lastOpenTime) - new Date(a.lastOpenTime)
        );
        setPaperList(sortedFiles);
        setIsFetching(false);
      } catch (error) {
       // setPaperList([]);
        setIsFetching(false);
      }
    };

    if (authData.auth) {
      fetchFiles();
    }
  }, [authData.auth]);

  const uploadClick = () => {
    setShowConfirmationModal(false);
    if (selectedPdf) {
      setSelectedFile(null);
      setSelectedPdfId(null);
      setShowDrop2(true);
    }
  };
  // console.log("selectedFilesState",selectedFiles);
  const confirmDelete = async (file) => {
    try {
      await axapi.post("/delete-pdf", { _id: file._id });
      setPaperList(paperList.filter((paper) => paper._id !== file._id));
      setShowBackdrop(false);
      if (file._id === selectedFile?._id) {
        setSelectedPdfId(null);
        setSelectedFile(null);
      }
      setShowDrop2(true);
      setShowConfirmationModal(false);
      uploadClick();

      // // Filter out the deleted file from selectedFilesState
      // setSelectedFiles((prevFiles) =>
      //   prevFiles.filter((file) => file.name !== filename)
      // );
      // console.log(selectedFiles);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setSessionExpired(true);
        handleLogout();
      } else {
        toast.error(
          "Error deleting file: " +
          (error.response.data.message || error.message),
          { autoClose }
        );
      }
    }
  };

  const cancelDelete = () => {
    setShowConfirmationModal(false);
    setShowBackdrop(false);
  };

  const cancelLogout = () => {
    setShowBackdrop(false);
    setLogoutConfirmationBox(false);
    if (!selectedPdf) {
      setShowDrop2(true);
    }
  };

  return (
    <div
      className={`${
        sidebarShow ? "flex flex-col justify-between" : "hidden"
        } h-[100vh] relative  w-[17%] bg-bkg dark:bg-[#1C1C1C]`}
    >
      <div className=" pl-6 pt-4 bg-white dark:bg-[#1C1C1C]">
        {
          isDarkMode ? 
          <VeritusLogoDarkSvg className="h-10 dark:stroke-[#F5F5F5] dark:fill-[#F5F5F5] stroke-[#000066]" />
          :
          <VeritusLogoSvg className="h-10" />}
      </div>
      {isFetching ? (
        <div className="h-[80vh] flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          {paperList && paperList.length > 0 ? (
            <FileList
              uploadClick={uploadClick}
              paperList={paperList}
              selectedPdfId={selectedPdfId}
              setSelectedFile={setSelectedFile}
              handlePdfSelection={handlePdfSelection}
              setShowConfirmationModal={setShowConfirmationModal}
              setShowDrop2={setShowDrop2}
              setShowBackdrop={setShowBackdrop}
            />
          ) : (
            <div className="h-[65vh] flex items-center justify-center dark:text-white">
              <div>No files uploaded</div>
            </div>
          )}
        </div>
      )}

      {showBackdrop && <div className="backdrop ml-[17%] z-50" />}

      {showConfirmationModal && (
        <DeleteConfirmationModal
          filename={showConfirmationModal.originalName}
          cancelDelete={cancelDelete}
          confirmDelete={()=>{confirmDelete(showConfirmationModal)}}
        />
      )}

      {showDrop2 && (
        <div className="absolute left-[100%]  transform   z-0 h-[100vh] w-[83vw] -translate-y-1/2 top-1/2 bg-[#E5E5F0] dark:bg-[#1C1C1C]">
          <Drop2
            onLoaded={(pdfFile) => handlePdfSelection(pdfFile.path, pdfFile._id)}
            setSelectedPdf={setSelectedPdf}
            setPaperList={setPaperList}
            paperList={paperList}
            setShowConfirmationModal={setShowConfirmationModal}
            setSelectedPdfId={setSelectedPdfId}
            setShowBackdrop={setShowBackdrop}
            confirmDelete={()=>{confirmDelete(showConfirmationModal)}}
            setSelectedFile={setSelectedFile}
            setShowDrop2={setShowDrop2}
          />
        </div>
      )}

      {showLogoutConfirmationBox && (
        <LogoutConfirmationModal
          cancelLogout={cancelLogout}
          handleLogout={handleLogout}
        />
      )}
      <div className="mt-3 ml-2 text-textPrimary gap-y-2 flex flex-col pb-6 pl-2">
        {/* <div
          onClick={() => {
            setIsDarkMode((dark)=>{
              return !dark;
            });
            saveModeToLocalStorage(); // Save mode on toggle
          }}
        >
          {isDarkMode ? (
            <DarkModeOutlinedIcon className="cursor-pointer dark:fill-[#DBE5FA]" />
          ) : (
            <LightModeOutlinedIcon className="cursor-pointer" />
          )}
        </div> */}

        {auth && (
          <div className="relative dark:text-[#DBE5FA] flex items-center">
            <FaRegUserCircle
              size={24}
              className="mr-2 cursor-pointer"
              onClick={openModal}
            />
            <span className="max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap ">
              {" "}
              {userName}
            </span>
            {showNotification && (
              <span className="absolute h-2 w-2 bg-[red] rounded-full top-0 left-4"></span>
            )}
          </div>
        )}
        <ReleaseNotes
          isOpen={isModalOpen}
          onClose={closeModal}
          position={modalPosition}
        />
        {auth && (
          <div
            className="flex items-center dark:text-[#DBE5FA] align-middle cursor-pointer hover:shadow-upload rounded-full hover:px-3 transition w-min"
            onClick={() => {
              setLogoutConfirmationBox(true);
              setShowBackdrop(true);
            }}
          >
            <MdLogout size={25} className=" mr-2 " />
            <span>Logout</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
