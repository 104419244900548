import React, { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import axapi from "../../../utils/axios";
import axios from "axios";
import { LinearProgress } from "@mui/material";
import { IoCloudUploadOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sessionExpiredState } from "../../../state/session";
import { useRecoilState } from "recoil";
import useLogout from "../../misc/logout";
import { VscFilePdf } from "react-icons/vsc";
import { FaCheckCircle, FaCircle, FaRegCheckCircle } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GiCancel } from "react-icons/gi";
import { selectedFilesState, selectedPdfId } from "../../../state/file";
import truncateResponse from "../../../utils/truncteResponse";
import {
  getDocument,
  GlobalWorkerOptions,
} from "pdfjs-dist/legacy/build/pdf.js";
import { autoClose } from "./../../../constants";
import truncateInMiddle from "../../../utils/truncateInMiddle";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";

   
export default function Drop2({
  onLoaded,
  setPaperList,
  paperList,
  setShowConfirmationModal,
  setSelectedPdf,
  setSelectedPdfId,
  setShowBackdrop,
  confirmDelete,
  setShowDrop2,
  setSelectedFile,
}) {
  const [, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploading, setUploading] = useState(false);
  const [currentlyUploadingFiles, setCurrentlyUploadingFiles] = useState([]);
  const [completedUploads, setCompletedUploads] = useState([]);
  const [selectedFiles, setSelectedFiles] = useRecoilState(selectedFilesState);
  const [, setSessionExpired] = useRecoilState(sessionExpiredState);

  const cancelTokenSourceRef = useRef({});
  const canceledFilesRef = useRef([]);
  const logout = useLogout();
  const [uploadedCount, setUploadedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const MAX_CHAR_COUNT = 320000; // Set your desired character limit
  const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes
  const MAX_PAGE_COUNT = 120;

  const onDrop = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        let newFile = acceptedFiles[0];
      
        if (newFile.type !== 'application/pdf') {
          toast.error('Only PDF files are allowed', { autoClose });
          return;
        }

        // File size check before reading the file
        if (newFile.size > MAX_FILE_SIZE) {
          toast.error("File exceeds the maximum size limit of 20 MB.", {
            autoClose,
          });
          return;
        }

        const reader = new FileReader();
        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const loadingTask = getDocument(arrayBuffer);

          try {
            const pdfDoc = await loadingTask.promise;
            const numPages = pdfDoc.numPages;

            // Check the number of pages
            if (numPages > MAX_PAGE_COUNT) {
              toast.error("File exceeds the maximum page limit of 120.", {
                autoClose,
              });
              return;
            }

            // Check for character count by reading text from each page
            let totalCharCount = 0;

            for (let pageNum = 1; pageNum <= numPages; pageNum++) {
              const page = await pdfDoc.getPage(pageNum);
              const textContent = await page.getTextContent();
              const pageText = textContent.items
                .map((item) => item.str)
                .join("");
              totalCharCount += pageText.length;

              if (totalCharCount > MAX_CHAR_COUNT) {
                toast.error(
                  "File exceeds the maximum character limit of 320K.",
                  { autoClose }
                );
                return;
              }
            }

            const fileExists = paperList.some(
              (existingFile) => existingFile.originalName === newFile.name
            );

            if (fileExists) {
              let newName = newFile.name;
              let index = 1;
              // Check if the file name with appended number exists
              while (paperList.some((file) => file.originalName === newName)) {
                index++;
                const fileExtensionIndex = newFile.name.lastIndexOf(".");
                if (fileExtensionIndex !== -1) {
                  newName = `${newFile.name.slice(
                    0,
                    fileExtensionIndex
                  )} (${index})${newFile.name.slice(fileExtensionIndex)}`;
                } else {
                  newName = `${newFile.name} (${index})`;
                }
              }
              // newFile = { ...newFile, name: newName, originalName: newName };
              newFile = new File([newFile], newName, {
                originalName: newName,
                size: newFile.size,
                path: newFile.path,
                type: newFile.type,
                lastModified: newFile.lastModified,
              });
            }

            setSelectedFiles((prevFiles) => [...prevFiles, newFile]);
            setTotalCount(1);
            // Initiate the upload process immediately when a file is dropped or selected
            handleUpload([newFile]);
          } catch (error) {
            console.error(error);
            toast.error(`Error processing PDF document: ${error}`, {
              autoClose,
            });
          }
        };

        reader.readAsArrayBuffer(newFile);
      }

      if (fileRejections?.length > 0) {
        const rejectedFile = fileRejections[0].file;
        toast.error(`File "${rejectedFile.name}" is not a valid PDF.`, {
          autoClose,
        });
      }
    },
    [uploading]
  );

  const handleUpload = async (filesToUpload) => {
    try {
      setError(null);
      setUploading(true);

      const initialUploadProgress = {};
      filesToUpload.forEach((fileToUpload) => {
        initialUploadProgress[fileToUpload.name] = 0;
      });
      setUploadProgress(initialUploadProgress);

      const successfulUploads = [];

      const uploadPromises = filesToUpload.map(async (fileToUpload) => {
        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenSourceRef.current[fileToUpload.name] = cancelTokenSource;

        setCurrentlyUploadingFiles((prevFiles) => [
          ...prevFiles,
          fileToUpload.name,
        ]);

        const uploadOptions = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            const fileSize = fileToUpload.size || progressEvent.total;
            const uploadedBytes = Math.round(
              (fileSize * percentCompleted) / 100
            );

            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [fileToUpload.name]: uploadedBytes,
            }));
          },
          cancelToken: cancelTokenSource.token,
        };

        const formData = new FormData();
        formData.append("file", fileToUpload);

        try {
          const response = await axapi.post(
            "/upload-pdf",
            formData,
            uploadOptions
          );

          if (!canceledFilesRef.current.includes(fileToUpload.name)) {
            const newFile = {
              originalName: response.data.body.name,
              _id: response.data.body._id,
              path: response.data.body.path,
              size: fileToUpload.size,
            };

            setSelectedPdfId(newFile._id);

            setSelectedFile(newFile);
            setShowDrop2(false);
            setPaperList((prevList) => [newFile, ...prevList]);
            onLoaded(newFile);
            setCompletedUploads((prevUploads) => [
              ...prevUploads,
              fileToUpload.name,
            ]);
            successfulUploads.push(fileToUpload.name);

            setCurrentlyUploadingFiles((prevFiles) =>
              prevFiles.filter((fileName) => fileName !== fileToUpload.name)
            );
          } else {
            console.log(`File upload canceled: ${fileToUpload.name}`);
          }

          return { success: true, file: fileToUpload.name };
        } catch (uploadError) {
          console.error(
            `Error uploading file "${fileToUpload.name}":`,
            uploadError
          );
          if (axios.isCancel(uploadError)) {
            canceledFilesRef.current.push(fileToUpload.name);
          } else {
            if (uploadError.response && uploadError.response.status === 401) {
              setSessionExpired(true);
              logout();
            } else if (
              uploadError.response &&
              uploadError.response.data.message.includes(
                "The specified key does not exist."
              )
            ) {
              toast.error(
                "File name includes invalid characters. Please change the file name and try again",
                { autoClose }
              );
            } else if (
              uploadError.response &&
              uploadError.response.data.message.includes(
                "Error reading PDF content"
              )
            ) {
              toast.error("The file content is empty or unreadable", {
                autoClose,
              });
            } else if (
              uploadError.response &&
              uploadError.response.data.message.includes(
                "Duplicate file exists"
              )
            ) {

              toast.error(truncateResponse(uploadError?.response?.data?.message), {
                autoClose,
              });
            } else {
              toast.error(
                "An error occurred while uploading the file",
                { autoClose }
              );
            }
          }

          return { success: false, file: fileToUpload.name };
        } finally {
          cancelTokenSourceRef.current[fileToUpload.name] = null;
          setSelectedFiles([]);
          setUploading(false);
        }
      });

      const results = await Promise.all(uploadPromises);

      if (successfulUploads.length > 0) {
        setUploadedCount((prevCount) => prevCount + successfulUploads.length);
        toast.success("File uploaded successfully!", { autoClose });
      }
    } catch (error) {
      toast.error(`An error occurred while uploading the file`, { autoClose });
      // console.error("Error uploading files:", error);
      // toast.error(`Error uploading files: ${error.message}`);
    } finally {
      setUploading(false);
      //   setTimeout(() => {
      //     setCurrentlyUploadingFiles([]);
      //     setSelectedFiles([]);
      //     //setShowDrop2(false);
      //     setUploadedCount(0);
      //     setTotalCount(0);
      //   }, 1000);
    }
  };

  // const removeFile = async (fileToRemove) => {
  //   try {
  //     const selectedPaper = paperList.find(
  //       (paper) => paper.originalName === fileToRemove.name
  //     );

  //     if (selectedPaper) {
  //       // Directly delete the file without showing the confirmation modal
  //       await axapi.post("/delete-pdf", { _id: selectedPaper._id });

  //       // Update the paperList state
  //       setPaperList((prevList) =>
  //         prevList.filter((paper) => paper._id !== selectedPaper._id)
  //       );

  //       // Update the selectedFilesState by removing the deleted file
  //       setSelectedFiles((prevFiles) =>
  //         prevFiles.filter((file) => file.name !== selectedPaper.originalName)
  //       );

  //       // Notify the user about successful deletion
  //       // toast.success(`File "${selectedPaper.originalName}" deleted successfully!`);
  //     } else {
  //       console.error("Corresponding file not found in paperList.");
  //     }
  //   } catch (error) {
  //     console.error("Error removing file:", error);
  //     toast.error(`Error removing file: ${error.message}`);
  //   }
  // };

  // const cancelUpload = (fileToCancel) => {
  //   try {
  //     if (cancelTokenSourceRef.current[fileToCancel.name]) {
  //       // Cancel the specific file upload
  //       cancelTokenSourceRef.current[fileToCancel.name].cancel(
  //         `File upload canceled: ${fileToCancel.name}`
  //       );
  //       delete cancelTokenSourceRef.current[fileToCancel.name];

  //       // Remove the canceled file from the currentlyUploadingFiles state
  //       setCurrentlyUploadingFiles((prevFiles) =>
  //         prevFiles.filter((fileName) => fileName !== fileToCancel.name)
  //       );
  //     } else {
  //       console.error(`Cancel token source not found for file: ${fileToCancel.name}`);
  //     }
  //   } catch (error) {
  //     console.error("Error canceling upload:", error);
  //   }
  // };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: uploading ? undefined : onDrop,
    accept: "application/pdf",
    noClick: true,
  });

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <div
      {...getRootProps()}
      className={`h-full w-full relative bg-[#E5E5F0] dark:bg-[#1C1C1C]`}
    >
      <div
        className={`text-center rounded-[5px] ${
          isDragActive ? "opacity-30" : ""
        } `}
      >
        {!selectedFiles.length && (
          <div
            className={`absolute rounded-[5px] border-2 border-dashed border-[#000066]  transform  bg-[#F2F2F8] dark:bg-[#f2f2f81A] flex justify-center top-[1%] translate-x-[1%]  h-[98%] w-[98%] text-white `}
          >
            <div
              className={`dropzone   rounded-[5px] flex  items-center justify-center gap-2 mx-auto ${
                selectedFiles.length > 0 ? "flex-row" : " flex-col"
              }`}
            >
              <input {...getInputProps()} />

              <div className="text-[#000066] dark:text-white">
                <IoCloudUploadOutline className="mt-1 mx-auto mb-1 text-bold" size={24} />
                Drag and Drop here <div className="mx-auto font-extrabold pt-[4px]">OR</div>
                <button
                  onClick={() => {
                    const fileInput = document.getElementById("fileInput");
                    fileInput && fileInput.click();
                  }}
                  className=" bg-[#000066] dark:bg-white text-white dark:text-black px-3 py-1 rounded-full  mx-auto  text-[16px] font-sem border border-[#0037A5] border-[#1C1C1C] font-[500] mt-2"
                >
                  Select File
                </button>
              </div>
            </div>

            <input
              type="file"
              accept="application/pdf"
              id="fileInput"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files) {
                  onDrop(Array.from(e.target.files));
                } else {
                  // Handle the case where files are not detected
                  toast.error(
                    "No file selected or the file selection was cancelled.",
                    { autoClose }
                  );
                }
              }}
            />
          </div>
        )}
      </div>
      {selectedFiles.length > 0 && (
        <div
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[37%] max-h-[60%] overflow-y-auto p-4  bg-[#00006603] rounded-lg "
          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
        >
          {/* <div className="flex py-2 gap-3 -ml-1">
            {uploading ? (
              <div className="loader my-1"></div>
            ) : (
              <FaRegCheckCircle className="text-green-600 text-[20px]" />
            )}
            <div className=" text-white text-[13px]">
              {" "}
              {uploadedCount} of {totalCount} Files Uploaded
            </div>
          </div> */}
          <div className="flex flex-col gap-4">
            {selectedFiles.map((file, index) => (
              <div
                key={index}
                className="bg-white px-5 py-1 rounded-xl  "
                style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                <div className="flex items-center justify-between gap-1">
                  <VscFilePdf size={24} className="my-auto " />
                  <div className="w-[100%] ml-3 flex flex-col gap-y-1">
                    <div className="flex justify-between">
                      <div className="text-[16px]">
                        {truncateInMiddle(file.name, 30)}
                      </div>
                      <div>
                        {/* {completedUploads.includes(file.name) ? (
                        <RiDeleteBin6Line
                          onClick={() => {
                            removeFile(file);
                          }}
                          className="cursor-pointer"
                        />
                      ) : (
                        <div>
                          {canceledFilesRef.current.includes(file.name) &&
                          !completedUploads.includes(file.name) ? (
                            <div className="text-[14px] font-[500] flex align-middle text-red-600">
                              Canceled
                            </div>
                          ) : (
                            <GiCancel
                              className="cursor-pointer"
                              onClick={() => cancelUpload(file)}
                            />
                          )}
                        </div>
                      )} */}
                      </div>
                    </div>

                    {uploading &&
                      currentlyUploadingFiles.includes(file.name) &&
                      !completedUploads.includes(file.name) && (
                        <div className="text-[12px] tracking-wider font-[500] flex  align-middle text-[#A9ACB4]">
                          {formatBytes(uploadProgress[file.name] || 0)} of{" "}
                          {formatBytes(file.size)}
                          <FaCircle size={6} className=" ml-2 my-auto" />
                          <div className="custom-loader mx-2 my-auto"></div>
                          <span className="text-[#292D32]">Uploading...</span>
                        </div>
                      )}

                    {completedUploads.includes(file.name) && (
                      <div className="text-[12px] tracking-wider font-[500] flex  align-middle text-[#A9ACB4]">
                        {formatBytes(file.size)} of {formatBytes(file.size)}
                        <FaCircle size={6} className=" ml-2 my-auto" />
                        <FaCheckCircle className="text-[#3EBF8F] text-[14px] mx-2 my-auto" />
                        <span className="text-[#292D32]">Completed</span>
                      </div>
                    )}
                  </div>
                </div>
                {currentlyUploadingFiles.includes(file.name) && (
                  <LinearProgress
                    variant="determinate"
                    value={((uploadProgress[file.name] || 0) / file.size) * 100}
                    className="rounded-l-full rounded-r-full w-full my-1 "
                    sx={{
                      backgroundColor: "#CBD0DC",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#000066",
                      },
                    }}
                    style={{ height: "5px" }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
