import React from 'react';
import { useRecoilValue } from 'recoil';
import { authState } from '../../state/auth';
import { Navigate } from 'react-router-dom';

/**
 * UnAuthGuard Component
 *
 * This component acts as a guard for routes that should only be accessible to
 * unauthenticated users. It checks the authentication state using Recoil
 * state management and conditionally renders the provided component or
 * navigates the user to the home page based on their authentication status.
 *
 * @param {Object} props - Component properties.
 * @param {React.Component} props.component - The component to be rendered for unauthenticated users.
 * @returns {React.ReactNode} - The rendered component or a redirect to the home page.
 */
const UnAuthGuard = ({ component }) => {
 // Retrieve the authentication state from Recoil state management
 const { auth } = useRecoilValue(authState);

 // Conditionally render the provided component or navigate to the home page
 return !auth ? <>{component}</> : <Navigate to={'/'} />;
};

export default UnAuthGuard;
