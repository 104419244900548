import React from 'react';
import { useRecoilValue } from 'recoil';
import { authState } from '../../state/auth';

const AuthGuard = ({ component }) => {
  const { auth } = useRecoilValue(authState);

  return auth ? <>{component}</> : null;
};

export default AuthGuard;
