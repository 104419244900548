import { atom, selector } from 'recoil';

/**
 * Atom for managing authentication state.
 * @type {RecoilAtomFamily<{ auth: boolean; token: string; user: string; }>}
 */
export const authState = atom({
  key: 'auth',
  default: {
    auth: false,
    token: '',
    user: '',
  },
});

/**
 * Selector for deriving a boolean value indicating authentication status.
 * @type {RecoilSelectorFamily<boolean, void>}
 */
export const authSelector = selector({
  key: 'authSelector',
  /**
   * Selector function to get the derived authentication status.
   * @param {Object} param - Recoil selector parameters.
   * @param {Function} param.get - Recoil getter function.
   * @returns {boolean} - Derived authentication status.
   */
  get: ({ get }) => {
    // Retrieve the 'auth' property from authState and convert it to a boolean.
    const isAuth = get(authState).auth;
    return !!isAuth;
  },
});

