import axios from 'axios';
import { setRecoil } from 'recoil-nexus';
import { loadingState } from '../state/loading';
// import getCurrentToken from "./../components/misc/current-token";


export const baseURL = 'https://server.veritus.ai';
// export const baseURL = 'http://localhost:3000';
// export const baseURL = 'https://veritus-server.sharath.uk';
const baseAPI = '/api/v1';

const axapi = axios.create({
  baseURL: baseURL + baseAPI,
  withCredentials: true,
});


axapi.interceptors.request.use(config => {

  const token = localStorage.getItem('token');
 
  if (token) {
    config.headers['authorization'] = `Bearer ${token}`

  }
  return config;
}, error => {
  return Promise.reject(error);
});


axapi.interceptors.request.use(
  function (request) {
    setRecoil(loadingState, true);
    return request;
  },
  function (error) {
    setRecoil(loadingState, false);
    return Promise.reject(error);
  }
);

axapi.interceptors.response.use(
  function (response) {
    setRecoil(loadingState, false);
    return response;
  },
  function (error) {
    setRecoil(loadingState, false);
    return Promise.reject(error);
  }
);

export default axapi;
