import React, { useRef, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Chat } from "./Chat";
import { PDFViewer } from "./PDFViewer";
import { selectedFile as selectedFileAtom } from "./../../state/file";
import { initSocket } from "../../socket";
import Sidebar from "./Sidebar/Sidebar";
import { jwtDecode } from 'jwt-decode';
import useLogout from "./../misc/logout";
import { ToggleTheme } from "../../state/toggleTheme";


export const HomePage = () => {
  const[sidebarShow,setSidebarShow] = useState(true)
  const [selectedFile] = useRecoilState(selectedFileAtom);
  const [isDarkMode, setIsDarkMode] = useRecoilState(ToggleTheme);

  const socketRef = useRef(null);
  const logout = useLogout();

  const [userName, setUserName] = useState('');
  if (!socketRef.current) {
    socketRef.current = initSocket();
  }
  
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === "token") { 
        window.location.reload();
      }
    };
    window.addEventListener('storage', handleStorageChange);
   
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
  
        // Check if the token is expired
        const currentTime = Date.now() / 1000; // Convert to seconds
        if (decoded.exp < currentTime) {
         logout();
        } else {
          // Token is valid
          setUserName(decoded.username);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        logout();
      }
    } else {
      logout();// Redirect to login page or handle logout
    }
  }, []);
  let file = selectedFile;
  // console.log('darkmode', isDarkMode)

  return (
    <div
      className="flex relative w-full "
      // data-theme={isDarkMode ? "dark" : "light"}
      
    >
      <Sidebar userName={userName} sidebarShow={sidebarShow} />

      <div
        className={`${
          sidebarShow ? "w-[83%]" : "w-full"
        }   flex  ${file?"":""} border`}
      >
        {/* <FileDrawer className=""/> */}
        {file && (
          <PDFViewer
            file={file}
            sidebarShow={sidebarShow}
            setSidebarShow={setSidebarShow}
          />
        )}
        {file && <Chat socket={socketRef.current} file={file} />}
      </div>
    </div>
  );
};

export default HomePage;
